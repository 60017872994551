//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import { mapState } from "vuex";
export default {
  mixins: [MixinTeamOptions],
  components: {
    XHeader: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    taskInfo: () => import("./taskInfo"),
  },
  data() {
    return {
      functionName: "任务管理",
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      dateRange: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date(),
      ],

      pageData: {
        pageIndex: 1,
        pageSize: 30,
        dataNum: 0,
        currentPage: 1,
      }, //分页
      selPros: [], //项目筛选
      userArr: [], //筛选选择的成员
      selMem: null,
      drawer: false, //移动端筛选抽屉
      selRow: null,
      monthVal: new Date(),
      loading: false,
      selDepart: [], //部门筛选
      userList: [],
    };
  },
  computed: {
    ...mapState(["isAppTag"]),
    startTime() {
      return this.dateRange && this.dateRange.length ? this.dateRange[0] : null;
    },
    endTime() {
      return this.dateRange && this.dateRange.length > 1
        ? this.dateRange[1]
        : null;
    },
  },
  methods: {
    handleGetData() {
      this.getUser();
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.handleGetData();
      }
    },

    /**
     * 移动端任务详情抽屉
     */
    handleMobileInfoDrawer() {
      if (this.selMem) {
        this.$refs.taskInfo.mobileDrawer(true);
      } else {
        this.openDrawer();
      }
    },
    /**
     * 获取成员
     */
    getUser() {
      this.loading = true;
      const data = {
        // deptId: this.selDepart.map((m) => m.RId),
        // eventeIds: this.selPros.map((m) => m.proId),
        teamId: this.teamValue,
        userIds: this.userArr.map((m) => m.UsId),
        PageIndex: this.pageData.pageIndex,
        PageSize: this.pageData.pageSize,
        sTime: this.startTime?.timeFormat("yyyy-MM-dd"),
        eTime: this.endTime?.timeFormat("yyyy-MM-dd"),
      };
      this.$http
        .post("/Task/GetMemberData.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.userList = resp.data.Data;
            this.pageData.dataNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 筛选
     */
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.drawer = false;
      this.getUser();
    },
    /**
     * 页面返回到列表页面
     */
    memListPage() {
      this.selMem = null;
      this.$router.replace(`/memberTask`);
    },
    /**
     * 查看详情
     */
    handleTaskInfo(val) {
      this.selMem = val;
    },
    /**
     * 获取项目
     */
    getProject(val) {
      this.selPros = val;
    },
    /**
     * 选择部门
     */
    getDepart(arr) {
      this.selDepart = arr;
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.userArr = arr;
    },
    /**
     * 抽屉关闭
     */
    handleDrawerClose() {
      this.drawer = false;
    },
    /**
     * 抽屉打开
     */
    openDrawer() {
      this.drawer = true;
    },
    /**
     * 设置每页条数
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getUser();
    },
    /**
     * 去往某一页
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getUser();
    },
  },
  mounted() {
    if (this.$route.params.teamId) {
      this.teamValue = Number.parseInt(this.$route.params.teamId);
    }
    if (this.teamValue && this.$route.params.userId) {
      this.selMem = { UserId: Number.parseInt(this.$route.params.userId) };
    }
  },
};
